
import Banner from './banner/Banner'
import About from './about/About'
import Services from './services/Services'
import Projects from './projects/Projects'
import ContactUs from './contact/ContactUs'
import Footer from '../Common/includes/footer/Footer'
import Choose from './choose/Choose'
import Clients from './clients/Clients'
// import Offers from './offers/Offers'
// import Expertise from './expertise/Expertise'
// import Contact from './contact/Contact'


const Index = () => {
    return (
        <>
            <Banner />
            <About />
            <Services />
            {/* <Projects /> */}
            
            {/* <Expertise /> */}
            
            {/* <Footer/> */}
            <Choose />
            <Clients />
            <ContactUs />
            {/* <Expertise />
            <Contact /> */}
        </>
    )
}

export default Index
