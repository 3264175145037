import styled from "styled-components";

export const ChooseWapper = styled.section`
.comparing{
    margin-top:20px;
    margin-bottom:60px;
}
.comparing .Box-shadow{
    // border:1px solid #D8DBEF;
    // border-radius:20px;
}
.Choose-hq{
    position: relative;
    background-color: var(--white);
    box-shadow: 10px 10px 34px rgba(0, 0, 0, 0.1);
    // -webkit-box-shadow: 7px 7px 24px rgba(0, 0, 0, 0.1);
    // -moz-box-shadow: 7px 7px 24px rgba(0, 0, 0, 0.1);
    padding: 40px 20px 0px 30px;
    height: 100%;
   .compareIcon{
        background-color: var(--white);
        box-shadow: 7px 7px 24px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 7px 7px 24px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 7px 7px 24px rgba(0, 0, 0, 0.1);
   }
}
.project-other {
    position: relative;
    padding: 40px 18px 0px 25px;
    height: 100%;
    background-color: #F8F8F8;
    & .project-bg{
        position: absolute;
        bottom: 0px;
        right: 40px;
        width:190px;
    }
    .compareIcon{
        background-color: #EEEEEE;
    }
}
.project-wapper{
    margin: 20px 0;
}
.project-content{
    display: flex;
    align-items: flex-start;
    gap: 32px;
    
    &>div{
        width:100%;
    }
}
.compare{
    display: flex;
    align-items: flex-start;
    gap: 32px;
    &>div{
        width:100%;
    }
}
    .project-content{
        display: flex;
        width: 100%;
        .compareIcon{
            display: flex;
            min-width: 78px;
            max-width: 78px;
            height: 78px;
            align-items: center;
            justify-content: center;
            position: relative;
            .compareCr{
                position: absolute;
                right: -10px;
                top: 15px;
            }
        }
    }
    .redefine{
        position: relative;
        .redefineContent{
            position: absolute;
            top: 370px;
            right: 120px;
            .main-heading-redefine{
                 color: var(--white);
                 font-size: 60px;
                 &:after{
                    display: none;
                 }
            }
        }
    }
`
