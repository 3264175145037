import React from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SubHeading, TextStyled } from '../../styled/Elements'
import { AboutImg, AboutImgAr, ArrowRight } from '../../Common/AllImages'
import { ContactButton } from '../../styled/CustomElements'

const About = () => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+966505444408';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <>
            <AboutWapper className='container-fluid common-space aboutFluid' id='about'>
                <Container>
                    <Box className='cmn-text-card row'>
                        <Col lg={5} xl={6} md={12}>
                            <LazyImage className="cmnImg imgResEn" src={AboutImg} />
                            <LazyImage className="cmnImg imgResAr" src={AboutImgAr} />
                        </Col>
                        <Col lg={7} xl={6} md={12}>
                            <MainHeading className='main-heading tStripe'>{t('aboutTitle')}</MainHeading>
                            <SubHeading className='sub-heading tStripe mb-3'>{t('aboutTitle2')}</SubHeading>
                            <TextStyled>
                                {t('aboutText')}
                            </TextStyled>
                            
                            <ContactButton icon={ArrowRight} label={t('Contact_Us')} onClick={WhatsApp} />
                        </Col>
                    </Box>
                    
                </Container>
            </AboutWapper>
        </>
    )
}
const AboutWapper = styled.section`
    padding-bottom: 0;
    
`
export default About
