import React from "react";
import { ContactUsWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { Box, MainHeading, SubHeading } from "../../styled/Elements";
// import { Message, Phone, WhatsappIcon } from "../../styled/AllImages";
import { ButtonWithIcon } from "../../styled/CustomElements";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";
import { socialIcon } from "../../../data/Data";
import { Message, Phone, WhatsappIcon } from "../../Common/AllImages";

const ContactUs = () => {
	const { t } = useTranslation();
	const Map = "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3623.4061859876338!2d46.723887280677246!3d24.747258732851765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ0JzQ4LjgiTiA0NsKwNDMnMjYuMiJF!5e0!3m2!1sen!2ssa!4v1720293581465!5m2!1sen!2ssa";
		// "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3623.4192512474206!2d46.72300751612039!3d24.74681052947398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ0JzQ4LjgiTiA0NsKwNDMnMjYuMiJF!5e0!3m2!1sen!2ssa!4v1720226747838!5m2!1sen!2ssa";
	const WhatsApp = () => {
		const phoneNumber = "+966505444408";
		const message = "Hello, HQ Engineering";
		const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
			message
		)}`;
		window.open(whatsappURL, "_blank");
	};
	return (
		<ContactUsWrapper id="contact" className="">
			<Box className="contact-box">
				<iframe
					src={Map}
					allowfullscreen=""
					loading="lazy"
					title="location"
					referrerpolicy="no-referrer-when-downgrade"
				></iframe>
			</Box>
			<Container>
				

				<Row className="contactMRow">
					
					<Col lg={5} xl={4} className="contact-Us">
						<Box className="contact-head">
							<MainHeading
								className="lightOrange_gradient main-heading contact-heading"
								size="35px"
								weight="bolder"
							>
								{t("Contact_Us")}
							</MainHeading>
							<SubHeading className='sub-heading tStripe mb-3' size="16px">{t('let_Connect_Life')}</SubHeading>
						</Box>
						<Box className="contect-with-us">
							<ContactForm />
						</Box>
					</Col>
					<Col lg={7} xl={8} className="contactRowWrapper">
						<Box className="contactRow">
							<Box className="contactNumCol">
								<ButtonWithIcon
								className="cntct-btn d-flex"
								label="+966 505444408"
								type="tel:+966505444408"
								icon={Phone}
								center
								orange
								/>
								<span className="separator"></span>
								<ButtonWithIcon
								className="cntct-btn d-flex"
								label="+966 505444408"
								onClick={WhatsApp}
								icon={WhatsappIcon}
								center
								orange
								/>
								<span className="separator"></span>
								<ButtonWithIcon
								className="cntct-btn d-flex"
								label="Info@hqd-sa.com"
								icon={Message}
								center
								orange
								/>
							</Box>
							<Box className="contactSocial">
								{socialIcon.map((list, key) => (
										<Box key={key} className='contact-links'>
											<a href={list.link} target="_blank" rel="noopener noreferrer">
												{list.img}
											</a>
										</Box>
									))
								}
							</Box>
						</Box>
					</Col>
				</Row>
			</Container>
		</ContactUsWrapper>
	);
};

export default ContactUs;
