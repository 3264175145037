import React from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SubHeading, TextStyled } from '../../styled/Elements'
import { AboutImg, ArrowRight, ServicesImg } from '../../Common/AllImages'
import { ContactButton } from '../../styled/CustomElements'
import { ClientData, servicesItems } from '../../../data/Data'

const Clients = () => {
    const { t } = useTranslation()
    
    return (
        <>
            <ClientsWapper className='container-fluid common-space clientsFluid' id='clients'>
                <Container className='clientsContentContainer'>
                    <Box className='row'>
                        <Col md={12} className='clientHSCol'>
                            <MainHeading className='main-heading tStripe' color='var(--white)'>{t('Our_Clients')}</MainHeading>
                            <SubHeading className='sub-heading tStripe mb-3'>{t('Partnering_Visionaries')}</SubHeading>
                        </Col>
                        <Col md={12} className='clientsWrapperImgs'>
                            {ClientData.map((item, key) => (
                                <Box key={key} className='clientsItem'>
                                    {item.logo}
                                </Box>
                            ))}
                            
                        </Col>
                    </Box>
                    
                </Container>
                
            </ClientsWapper>
        </>
    )
}
const ClientsWapper = styled.section`
    .clientsWrapperImgs{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(7, 127px);
        justify-content: space-between;
        & > div{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        
    }
    
`
export default Clients
