import styled from "styled-components";

export const FooterWrapper = styled.footer`
  position: relative;
  padding: 12px 0;
  background-color: var(--primary);
  background-repeat: no-repeat;
  border-top: 1px solid #ad6fec;

  .footer-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    & a {
      color: var(--white);
      /* font-family: var(--semibold); */
      &:hover {
        // color: var(--text);
      }
    }
  }
  .footer-list a {
    color: var(--white);
    padding-bottom: 8px;
    position: relative;
    font-size: 16px;
    font-family: ${(props) =>
      props.lang === "en" ? "var(--regular)" : "var(--regularAr)"};
    font-weight: 400;
    cursor: pointer;
  }
  .footer-revised {
    position: relative;
    padding: 25px 25px 25px 25px;
  }
  .fowl {
    cursor: pointer;
    &:hover {
      color: var(--white);
    }
  }
  .footer-logo {
    width: 80px;
  }
  .f-logo-wrap {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .footer-heading {
    font-size: 32px;
    margin-bottom: 20px;
    &::after {
      display: none;
    }
  }
  .footerLinks {
    display: flex;
    align-items: flex-start;
    .footerLinksCol {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      & > div {
        display: flex;
        width: 100%;
      }
      a {
        font-size: 15px;
        text-transform: uppercase;
        color: var(--white);
        font-family: var(--regular);
      }
    }
  }
  .cntct-btn {
    margin-bottom: 20px;
    button {
      background-color: transparent !important;
      padding: 0 !important;
      span {
        padding: 0;
        font-family: var(--light);
      }
    }
  }
  .footerAddress {
    color: var(--white);
    font-family: var(--regular);
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-family: var(--light);
	margin-bottom: 20px;
    svg {
      min-width: 18px;
      max-width: 18px;
    }
	  .footerAddressWr{
		display: flex;
		flex-wrap: wrap;
	  }
    .footerAddressTitle {
      font-family: var(--medium);
      width: 100%;
    }
  }
  .footerContain {
    border-bottom: 1px solid #51587c;
  }
  .footerCol1 {
    padding-right: 80px;
  }
  .footerCol2 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .footerCol3 {
    padding-left: 80px;
  }
	.footerContain2{
	padding-top: 30px;
	padding-bottom: 30px;
	}
	.contactSocial{
			display: flex;
			align-items: center;
			gap: 15px;
			}
      .footer-row{
        display: flex;
        justify-content: space-between;
        & > div{
          width: fit-content;
        }
      }
`;
