import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import { privateRoute } from './routes/PrivateRoute';
// import Footer from './ui/includes/Footer';
import { useCookies } from 'react-cookie';
import i18n from './Languages/INEXT';
import Header from './components/Common/includes/Header';
import { Box } from './components/styled/Elements';
import Footer from './components/Common/includes/footer/Footer';

function App() {
	const location = useLocation();
	useEffect(() => {
		window.scroll({ top: 0, behaviour: 'smooth' })
	}, [location]);

	const [cookies, setCookie] = useCookies(['lang']);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setCookie('lang', lng, { path: '/' }); // Set cookie with the language preference
	};

	return (
		<>
			<Box>
				<Header />
				<Routes>
					{privateRoute.map((list, key) => (
						<Route to={key} {...list} />
					))}
				</Routes>
				<Footer />
			</Box>
		</>
	);
}

export default App;
