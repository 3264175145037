import React from "react";
import { FooterWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import {
	Box,
	HeadingStyled,
	LazyImage,
	MainHeading,
	SpanStyled,
	TextStyled,
} from "../../../styled/Elements";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { FooterLogo, ProductsImg } from "../../../styled/AllImages";
import { NavDataConfig, socialIcon } from "../../../../data/Data";
import { FooterBg, FooterLogo, Location, Logo, Message, Phone } from "../../AllImages";
import { ButtonWithIcon } from "../../../styled/CustomElements";

const Footer = () => {
	const { t, i18n } = useTranslation();
	const currentLang = i18n.language;
	const navigate = useNavigate();
	const date_year = new Date();
	const FowlUrl = () => {
		const whatsappURL = "https://fowl.sa/home";
		window.open(whatsappURL, "_blank");
	};
	const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
	return (
		<FooterWrapper className="container-fluid footerWrapper" lang={currentLang}>
			<Container className="footerContain">
				<Row>
					<Col lg={4} className="footerCol footerCol1">
						<MainHeading className='main-heading tStripe footer-heading' color='var(--white)'>{t('aboutTitle')}</MainHeading>
						<TextStyled color="var(--white)" size="14px" family="var(--light)">
							{t('aboutFootTxt')}
                        </TextStyled>
					</Col>
					<Col sm={6} lg={4} className="footerCol footerCol2">
						<MainHeading className='main-heading tStripe footer-heading' color='var(--white)'>{t('Links')}</MainHeading>
						<Box className="footerLinks">
							<Box className="footerLinksCol">
								{NavDataConfig.slice(0, 4).map((item, key) => (
									<Box key={key}>
										<Link to={item.to} spy={true} smooth={true} offset={-60} duration={500} activeClass="active" >{t(item.label)} </Link>
									</Box>
								))}
							</Box>
							<Box className="footerLinksCol">
								{NavDataConfig.slice(4, 7).map((item, key) => (
									<Box key={key}>
										<Link to={item.to} spy={true} smooth={true} offset={-60} duration={500} activeClass="active" >{t(item.label)} </Link>
									</Box>
								))}
							</Box>
						</Box>
						
						
					</Col>
					<Col sm={6} lg={4}  className="footerCol footerCol3">
						<MainHeading className='main-heading tStripe footer-heading' color='var(--white)'>{t('Contact_Us')}</MainHeading>
						<ButtonWithIcon
								className="cntct-btn d-flex"
								label="+966 505444408"
								type="tel:+966505444408"
								icon={Phone}
								center
								orange
								/>
								<ButtonWithIcon
								className="cntct-btn d-flex"
								label="Info@hqd-sa.com"
								icon={Message}
								center
								orange
								/>
						<Box className="footerAddress">
							<Location />
							<Box className="footerAddressWr">
								<span className="footerAddressTitle">{t('Head_Office')}</span>
								<span>{t('AnasBin')}</span>
							</Box>
							
						</Box>
						{/* <Box className="footerAddress">
							<Location />
							<Box className="footerAddressWr">
								<span className="footerAddressTitle">{t('Visitor_Center')}</span>
								<span>{t('AL_Wahah')}</span>
							</Box>
							
						</Box> */}
						 

					</Col>
				</Row>
				
			</Container>
			<Container className="footerContain2">
				<Row className="footer-row align-items-center">
					<Box>
						<Box className="f-logo-wrap">
							<Box className="logoHeader" onClick={() => navigate("/")}>
								<FooterLogo />
							</Box>
						</Box>
					</Box>
					
					<Box className='text-center copyright'>
						<SpanStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>{t('AllRightReserved')} &copy; {date_year.getFullYear()} </SpanStyled>
						<SpanStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'>
							<SpanStyled className='footer-op fowl' size='14px' family="var(--medium)" color='var(--secondary)'> {t('HQ_Engineering')}</SpanStyled>
						</SpanStyled>
						<HeadingStyled className='footer-op' size='13px' color='var(--white)' transform='capitalize'  family="var(--regular)" weight="normal">{t('Developed_by')}
							<SpanStyled className='fowl footer-op' color='var(--secondary)' size='14px' family="var(--medium)" onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
						</HeadingStyled>
					</Box>
					<Box className="contactSocial">
							{socialIcon.map((list, key) => (
									<Box key={key} className='contact-links'>
										<a href={list.link} target="_blank" rel="noopener noreferrer">
											{list.img}
										</a>
									</Box>
								))
							}
					</Box>
					{/* <Col lg={2} md={12} sm={12}>
						<Box className="d-flex align-items-center gap-3 footerSocial">
						<LazyImage src={ProductsImg} />
						</Box>
					</Col> */}
				</Row>
			</Container>
		</FooterWrapper>
	);
};

export default Footer;
