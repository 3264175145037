import React from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SubHeading, TextStyled } from '../../styled/Elements'
import { AboutImg, ArrowRight, ServicesImg, ServicesImgAr } from '../../Common/AllImages'
import { ContactButton } from '../../styled/CustomElements'
import { servicesItems } from '../../../data/Data'

const Services = () => {
    const { t } = useTranslation()
    
    return (
        <>
            <SevicesWapper className='container-fluid common-space servicesFluid' id='services'>
                <Container className='serviceContentContainer'>
                    <Box className='cmn-text-card row orderSwipe'>
                        <Col lg={5} xl={6} md={12}>
                            <LazyImage className="cmnImg imgResEn" src={ServicesImg} />
                            <LazyImage className="cmnImg imgResAr" src={ServicesImgAr} />
                        </Col>
                        <Col lg={7} xl={6} md={12}>
                            <MainHeading className='main-heading tStripe'>{t('servicesTitle')}</MainHeading>
                            <SubHeading className='sub-heading tStripe mb-3'>{t('servicesTitle2')}</SubHeading>
                            <TextStyled>
                                {t('servicesText')}
                            </TextStyled>
                        </Col>
                    </Box>
                    
                </Container>
                <Container>
                    <Box className='servicesItemRow'>
                        {servicesItems.map((item, index) => (
                            <Box className='servicesItemCol'>
                                <Box className='servicesItemNumber'>
                                    {t(item.serviceNumber)}
                                </Box>
                                <Box className='servicesItemIcon'>
                                    {item.img}
                                </Box>
                                <Box className='servicesItemTitle'>
                                    {t(item.serviceTitle)}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    
                </Container>
            </SevicesWapper>
        </>
    )
}
const SevicesWapper = styled.section`
    .serviceContentContainer{
        margin-bottom: 80px;
    }
    .servicesItemRow{
        display: flex;
        gap: 30px;
        width: 100%;
        flex-wrap: wrap;
        // background-color: #0000ff;
        
        .servicesItemCol{
            display: flex;
            flex-wrap: wrap;
            padding: 22px 5px 35px 5px;
            background-color: var(--white);
            width: calc(20% - 24px);
            position: relative;
            flex-direction: column;
            box-shadow: 16px 16px 24px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 16px 16px 24px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 16px 16px 24px rgba(0, 0, 0, 0.1);
            .servicesItemNumber{
                position: absolute;
                font-family: var(--bold);
                font-size: 32px;
                color: rgba(0, 0, 0, 0.1);
                left: 6px;
                top: 0px;
            }
            .servicesItemIcon{
                display: flex;
                width: 100%;
                justify-content: center;
            }
            .servicesItemTitle{
                display: flex;
                width: 100%;
                text-align: center;
                justify-content: center;
                font-family: var(--medium);
                font-size: 16px;
                margin-top: 15px;
                text-transform: uppercase;
            }
        }
    }
    
`
export default Services
