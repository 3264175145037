import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, LazyImage, MainHeading, SpanStyled, SubHeading, TextStyled } from '../../styled/Elements'
import { AboutImg, ArrowRight, ArrowLeftS, ArrowRightS, ZoomIcon } from '../../Common/AllImages'
import { ContactButton } from '../../styled/CustomElements'
import { galleryImages, scrollImages } from '../../../data/Data'
import FsLightbox from "fslightbox-react";
import Slider from 'react-slick'

const Projects = () => {
    const { t } = useTranslation();
    const [toggler, setToggler] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState(null);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        swipeToSlide: true,
        autoplay: false,
        speed: 1000,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        prevArrow: <ArrowLeftS />,
        nextArrow: <ArrowRightS />,
        responsive: [
        {
            breakpoint: 1200,
            settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            },
        },
        {
            breakpoint: 1099,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            },
        },
        {
            breakpoint: 991,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
            },
        },
        {
            breakpoint: 576,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            },
        },
        ],
    };

    const handleImageClick = (imageIndex) => {
        setToggler(!toggler);
        setActiveImageIndex(imageIndex);
    };
    useEffect(() => {
        if (toggler) {
          document.body.style.overflow = 'unset';
        }
      }, [toggler]);
    
    return (
        <>
            <ProjectsWapper className='container-fluid common-space projectsFluid' id='projects'>
                <Container className='serviceContentContainer'>
                    <Box className='row'>
                        <Col lg={12} className='headingWrapper'>
                            <MainHeading className='main-heading tStripe'>{t('Our_Projects')}</MainHeading>
                            <SubHeading className='sub-heading tStripe mb-3'>{t('Our_Projects_Sub')}</SubHeading>
                        </Col>
                    </Box>
                    <Box className='row'>
                        <Col lg={12}>
                            <Slider {...settings} className="scroll-container">
                                {scrollImages.map((x, i) => (
                                    <Box>
                                        <Box className="slide-item" onClick={(e) => handleImageClick(i)}>
                                        <LazyImage src={x.img} alt="" className="w-100" />
                                        <Box className="slide-overlay">
                                            <Box className="zoom-icon-container">
                                            <Box className="zoom-icon">
                                                <ZoomIcon />
                                            </Box>
                                            </Box>
                                        </Box>
                                        {x.slideLocation && (
                                            <Box className='projectLocation'>
                                                {t(x.slideLocation)}
                                            </Box>
                                        )}
                                        <Box className="slide-text">
                                            {x.slideTitle && (
                                                <TextStyled className='slideTitle'>{t(x.slideTitle)}</TextStyled>
                                            )}
                                            {x.slideText && (
                                                <TextStyled className='slideText'>{t(x.slideText)}</TextStyled>

                                            )}
                                        </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Slider>
                            <FsLightbox toggler={toggler} sources={galleryImages[activeImageIndex]} />
                        </Col>
                    </Box>
                </Container>
            </ProjectsWapper>
        </>
    )
}
const ProjectsWapper = styled.section`
    .scroll-container{
        position: relative;
    }
    .slide-item {
        overflow: hidden;
        cursor: pointer;
        position: relative;
        height: 500px;
        span{
            width: 100%;
        }
        .projectLocation{
            position: absolute;
            top: 50%;
            transform: translate(40%, -50%);
            left: -20px;
            margin: auto;
            color: var(--white);
            font-size: 18px;
            text-transform: uppercase;
            font-family: var(--medium);
            letter-spacing: 5px;
            rotate: -90deg;
            display: none;
            width: 130px;
            text-align: center;

        }
    }
    .slide-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(17, 23, 57, .7);
        opacity: 0;
        transition: opacity 0.3s ease;
        display: flex;
        align-items: flex-end;
    }
    .slide-item:hover .slide-overlay{
        opacity: 1;
    }
    .slide-item .slide-text p{
        width: 100%;
    }
    .slide-item:hover .slide-text p{
        display: inline-block;
        opacity: 1;
    }
    .slide-item:hover .projectLocation{
        display: block;
    }
    .slide-item img {
        object-fit: cover;
        height: 500px;
    }
    .zoom-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(132, 111, 93, 0.7);
        padding: 20px;
    }
    .slide-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: white;
        padding: 20px;
        box-sizing: border-box;
    }

    .slide-text .slideTitle {
        margin: 0;
        font-size: 18px;
        line-height: 22px;
        font-family: var(--medium);
        color: var(--white);
        text-transform: uppercase;
    }

    .slide-text .slideText {
        display: none;
        opacity: 0;
        margin: 5px 0 0;
        font-size: 15px;
        color: var(--white);
        line-height: 20px;
        transition: all ease-in-out .3s;
    }

    .slick-prev, .slick-next {
        position: absolute;
        top: 50%;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        z-index: 9;
    }

    .slick-prev {
        left: 10px;
        width: 20px;
        height: auto;
    }

    .slick-next {
        right: 10px;
        width: 20px;
        height: auto;
    }
    
`
export default Projects
