import React from "react";

import styled from "styled-components";

import { Whatsapp } from "../components/Common/AllImages";
import { LazyImage } from "../components/styled/Elements";

const ChangeButton = () => {
  const WhatsApp = () => {
    const phoneNumber = "+966505444408";
    const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <>
      <ButtonBox>
        <span className="whatsapp" onClick={WhatsApp}>
          <LazyImage src={Whatsapp} />
        </span>
      </ButtonBox>
    </>
  );
};
const ButtonBox = styled.div`
  padding: 0 20px;
  cursor: pointer;
  z-index: 1;
  .whatsapp {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
  }
`;

export default ChangeButton;
