import React, { useEffect, useState } from 'react'
import styled from 'styled-components'


import { Container, Row } from 'react-bootstrap'

import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'
import { NavDataConfig } from '../../../data/Data'
import { Box } from '../../styled/Elements'
import { HeadPhone, Logo } from '../AllImages'
import { ButtonWithIcon } from '../../styled/CustomElements'
import ChangeButton from '../../../Languages/ChangeButton'
import LangChangeButton from '../../../Languages/LangChangeButton'

const Header = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+966505444408';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <HeaderContainer className={`${isSticky ? 'sticky' : ''} container-fluid`}>
                <Container>
                    <Row>
                        <Box className='nav-bar'>
                            <Box className='logoHeader' onClick={() => navigate('/')}>
                                <Logo />
                            </Box>
                            <Box className='nav-bar-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500} activeClass="active" >{t(item.label)} </Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='d-flex align-items-center headerBtns'>
                                <ButtonWithIcon className="headerWhatsapp" icon={HeadPhone} label='+966 505444408' onClick={WhatsApp} />
                                <LangChangeButton/>
                                <span className="toggleBtn" onClick={handleToggle}>
                                    {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                                </span>
                            </Box>
                        </Box>
                    </Row>
                </Container>
                
            </HeaderContainer>
            <ChangeButton />
        </>
    )
}
const HeaderContainer = styled.header`

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        a{
            color:var(--primary);
            padding: 4px 12px 4px 12px;
            cursor: pointer;
            font-size: 16px;
            font-family: var(--medium);
            border: 1px solid transparent;
            display: block;
            &:hover{
                border: 1px solid var(--primary);
            }
            &.active{
                border: 1px solid var(--primary);
                font-family: var(--bold);
            }
        }
        
    } 
    
    .logoHeader svg{
        cursor:pointer;
        -webkit-transition: all .4s ease-out 0s;
        transition: all .4s ease-out 0s;
    }


    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // z-index: 100;
    // .nav-bar{
    //     display:flex;  
    //     align-items: center;
    //     justify-content: space-between;
    // }
    // .nav-bar-list{
    //     display:flex;  
    //     align-items: center;
    //     gap: 35px;
    //     & a{
    //         color:var(--white);
    //         padding-bottom: 12px;
    //         &:hover, &.active{
    //             color:var(--primary);
    //             border-bottom: 2px solid var(--primary);
    //         }
    //     }
    // }
    header.sticky{
        padding-top: 10px;
        padding-bottom: 10px;
        .logoHeader svg{
            width: 150px;
            height: auto;
        }
    }
    .headerBtns{
        gap: 5px;
    }
`

export default Header
