import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import { Box, HeadingStyled, MainHeading, SpanStyled, SubHeading, TextStyled } from '../../Styled/Elements'

import { ChooseWapper } from './styled'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, LazyImage, MainHeading, SubHeading, TextStyled } from '../../styled/Elements'
import { ProjectDigitalData, ProjectOtherData } from '../../../data/Data'
import { ArrowRight, Check, ChooseBg, Cross, RedefineImg, RedefineImgAr } from '../../Common/AllImages'
import { ContactButton } from '../../styled/CustomElements'

const Choose = () => {
    const { t, i18n } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966505444408';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    return (

        <ChooseWapper id='whyChoose' className='common-space'>
            <Container>
                <Row>
                    <Box className='row'>
                        <Col lg={12} className='headingWrapper'>
                            <MainHeading className='main-heading tStripe'>{t('Why_Choose_Us')}</MainHeading>
                            <SubHeading className='sub-heading tStripe mb-3'>{t('Expert_Solutions')}</SubHeading>
                        </Col>
                    </Box>
                </Row>
            </Container>
            <Container className='comparing'>
                <Box className='Box-shadow'>
                    <Row >
                        <Col lg={12} md={12}>
                            <Box className='Choose-hq'>
                                <HeadingStyled className='choose-heading' size='28px' color='var(--secondary)' weight='normal'>{t('Choosing_HQ')}</HeadingStyled>
                                {
                                    ProjectOtherData.map((list, key) => (
                                        <Box key={key} className='project-wapper'>
                                            <Box className='project-content compare'>
                                                <Box className="compareIcon">
                                                    <Box className='compareIconImg'>{list.img}</Box>
                                                    <Check className='compareCr compareCheck'/>
                                                </Box>
                                                <Box>
                                                    <HeadingStyled color='var(--primary)' size='18px' weight='normal' className='compareDataH'>{t(list.heading)}</HeadingStyled>
                                                    <TextStyled size="15px" className='compareDataT'>{t(list.text)}</TextStyled>
                                                </Box>
                                            </Box>
                                                
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Col>
                        {/* <Col lg={6} md={12} >
                            <Box className='project-other'>
                                
                                <HeadingStyled className='choose-heading' size='28px' color='var(--secondary)' weight='normal'>{t('Other_Companies')}</HeadingStyled>
                                {
                                    ProjectDigitalData.map((list, key) => (
                                        <Box key={key} className='project-wapper'>
                                            
                                            <Box className='project-content compare2'>
                                                <Box className="compareIcon">
                                                    {list.img}
                                                    <Cross className='compareCr compareCross'/>
                                                </Box>
                                                <Box>
                                                    <HeadingStyled color='var(--themeColor)' size='18px' weight='normal' className='compareDataH'>{t(list.heading)}</HeadingStyled>
                                                    <TextStyled size="15px" className='compareDataT'>{t(list.text)}</TextStyled>
                                                </Box>
                                            </Box>
                                                
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Col> */}
                    </Row>
                </Box >
            </Container >

            <Container className='redefine'>
                <LazyImage className="imgResEn" src={RedefineImg} />
                <LazyImage className="imgResAr" src={RedefineImgAr} />
                <Box className="redefineContent">
                    <MainHeading className='main-heading tStripe main-heading-redefine'>{t('Redefine_Your_Space')}</MainHeading>
                    <SubHeading className='sub-heading tStripe mb-3' color="var(--primary)">{t('Innovative_Designs')}</SubHeading>
                    <ContactButton icon={ArrowRight} label={t('Contact_Us')} onClick={WhatsApp} />
                </Box>
                

            </Container>
        </ChooseWapper >
    )
}

export default Choose