import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box } from '../../styled/Elements'
import { ContactButton } from '../../styled/CustomElements'
import { bannerItems } from '../../../data/Data'
import { ArrowLeftS, ArrowRightS, Logo } from '../../Common/AllImages'

const Banner = () => {
    const { t } = useTranslation();
    

    const WhatsApp = () => {
        const phoneNumber = '+966505444408';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };

    

    const SliderSetting = {
        infinite: true,
        slidesToShow: 1,
        swipeToSlide: true,
        autoplay: false,
        speed: 1000,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        prevArrow: <ArrowLeftS />,
        nextArrow: <ArrowRightS />,
    };



    return (
            <>
                <BannerWapper className='container-fluid banner-bg' id='home'>
                    <Box className='banner-wapper-card'>
                        
                        <Slider {...SliderSetting}>
                            {bannerItems.map((item, index) => (
                                <Box className='container-fluid mainSlide p-0'>
                                    {item.img}
                                    <Container className='containerMainSlider'>
                                        <Box className='sliderContentWrapper'>
                                            <Box className='sliderHeading'>
                                                {t(item.mainBannerHeading)}
                                            </Box>
                                            <Box className='sliderdetail'>
                                                <Box className='sliderTxt'>
                                                    {t(item.mainTxt)}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Container>
                                </Box>
                            ))}
                            
                        </Slider>
                        
                    </Box>
                    
                </BannerWapper >
            </>
        );
    };
    
export default Banner;
    
const BannerWapper = styled.section`
    .banner-wapper-card{
        position: relative;
        height: 100%;
    }
    .banner-card{
        padding - top:70px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .banner-text-card{
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 56%;
    }
    .bannerImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`


