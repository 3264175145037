import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import Cookies from "js-cookie";
import { Arabic, English, Whatsapp } from "../components/Common/AllImages";
import { ButtonStyled, LazyImage } from "../components/styled/Elements";

const LangChangeButton = () => {
  const { i18n } = useTranslation();
  const [isEnglishSelected, setIsEnglishSelected] = useState(
    Cookies.get("lang") === "en" ? false : true
  );

  const changeLanguageAndDirection = (lng, dir) => {
    i18n.changeLanguage(lng);
    //document.documentElement.dir = dir;
    document.body.classList.toggle("rtl", dir === "rtl");
    Cookies.set("lang", lng);
    Cookies.set("dir", dir);
  };
  const handleLanguageChange = (lng, dir) => {
    changeLanguageAndDirection(lng, dir);
    setIsEnglishSelected(!isEnglishSelected);
    Cookies.set("lang", lng, { expires: 60, path: "/" });
    Cookies.set("dir", dir, { expires: 60, path: "/" });
  };

  useEffect(() => {
    const savedLang = Cookies.get("lang");
    const savedDir = Cookies.get("dir");
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    } else {
      // Set default language to Arabic if not saved in cookies
      i18n.changeLanguage("ar");
      Cookies.set("lang", "ar", { expires: 60, path: "/" });
    }
    if (savedDir) {
      //document.documentElement.dir = savedDir;
      document.body.classList.toggle("rtl", savedDir === "rtl");
    } else {
      // Set default direction to RTL if not saved in cookies
      //document.documentElement.dir = "rtl";
      document.body.classList.toggle("rtl", true);
      Cookies.set("dir", "rtl", { expires: 60, path: "/" });
    }
  }, []);

  return (
    <>
      <ButtonBox>
        {isEnglishSelected ? (
            <ButtonStyled
              className="change-Btn"
              bg="var(--bgGray)"
              onClick={() => handleLanguageChange("en", "ltr")}
            >
              <span>English</span>
              <LazyImage src={English} className="english_icon pb-0" alt="USA" />
            </ButtonStyled>
        ) : (
            <ButtonStyled
              className="change-Btn"
              bg="var(--bgGray)"
              onClick={() => handleLanguageChange("ar", "rtl")}
            >
              <span>العربية</span>
              <LazyImage src={Arabic} className="arabic_icon pb-0" alt="Arabic" />
            </ButtonStyled>
        )}
      </ButtonBox>
    </>
  );
};
const ButtonBox = styled.div`
  padding: 0 5px;
  cursor: pointer;
  z-index: 1;
  .change-Btn {
    display: flex;
    align-items: center;
    padding: 5px 7px 5px 10px;
    height: 40px;
    border-radius: 0;
    img{
        width: 34px;
        height: auto;
        // margin: 3px 0;
        margin-left: 3px;
    }
    span{
      padding-bottom: 0;
      font-family: var(--arMedium);
    }
    span.lazy-load-image-loaded{
      display: flex;
    }
  }
  .english_icon {
    cursor: pointer;
    z-index: 1;
  }
  .arabic_icon {
    cursor: pointer;
    z-index: 1;
  }
`;

export default LangChangeButton;
