import { BannerImg1, Client1, Client10, Client11, Client12, Client13, Client14, Client2, Client3, Client4, Client5, Client6, Client7, Client8, Client9, Instagram, Linkedin, Logo, P10img1, P10img2, P10img3, P11img1, P11img2, P11img3, P11img4, P11img5, P11img6, P12img1, P12img2, P12img3, P12img4, P12img5, P13img1, P13img2, P13img3, P13img4, P14img1, P14img2, P15img1, P15img2, P15img3, P15img4, P16img1, P16img2, P16img3, P17img1, P18img1, P18img2, P19img1, P19img2, P19img3, P1img1, P1img2, P1img3, P2img1, P3img1, P3img2, P3img3, P4img1, P4img2, P4img3, P4img4, P4img5, P4img6, P5img1, P5img2, P5img3, P5img4, P6img1, P6img2, P6img3, P7img1, P7img2, P8img1, P8img2, P9img1, P9img2, P9img3, ServiceItem1, ServiceItem10, ServiceItem2, ServiceItem3, ServiceItem4, ServiceItem5, ServiceItem6, ServiceItem7, ServiceItem8, ServiceItem9, Tiktok, Twitter, WhyIcon1, WhyIcon2, WhyIcon3, WhyIcon4, WhyIcon5, WhyIcon6, WhyIcon7, WhyIcon8 } from "../components/Common/AllImages"
import { LazyImage } from "../components/styled/Elements"
// import img1 from "../assets/images/png/projects/project1/img1.png";
// import img2 from "../assets/images/png/projects/project2/img1.png";
// // import img3 from "../assets/images/png/projects/project3/img1.png";
// import img4 from "../assets/images/png/projects/project1/img1.png";
export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'services',
        label: 'Services',
    },
    // {
    //     to: 'projects',
    //     label: 'Projects',
    // },
    {
        to: 'whyChoose',
        label: 'WhyChoose',
    },
    {
        to: 'clients',
        label: 'Clients',
    },
    {
        to: 'contact',
        label: 'Contact',
    },
]
export const bannerItems = [
    {
        img: <img src={BannerImg1} className="bannerImg" />,
        mainBannerHeading: 'BannerH1',
        mainTxt: 'BannerT1',
        logoBanner: <Logo alt="logo" />,
        
    },
    {
        img: <img src={BannerImg1} className="bannerImg" />,
        mainBannerHeading: 'BannerH1',
        mainTxt: 'BannerT1',
        logoBanner: <Logo alt="logo" />,
        
    },
    {
        img: <img src={BannerImg1} className="bannerImg" />,
        mainBannerHeading: 'BannerH1',
        mainTxt: 'BannerT1',
        logoBanner: <Logo alt="logo" />,
        
    },
    

]
export const servicesItems = [
    {
        img: <ServiceItem1 className="serviceIcon" />,
        serviceTitle: 'Building_Permit',
        serviceNumber: '01',
    },
    {
        img: <ServiceItem2 className="serviceIcon" />,
        serviceTitle: 'Architecture_Design',
        serviceNumber: '02',
    },
    {
        img: <ServiceItem3 className="serviceIcon" />,
        serviceTitle: 'Structural_Design',
        serviceNumber: '03',
    },
    {
        img: <ServiceItem4 className="serviceIcon" />,
        serviceTitle: 'Interior_Design',
        serviceNumber: '04',
    },
    {
        img: <ServiceItem5 className="serviceIcon" />,
        serviceTitle: 'Urban_Planning',
        serviceNumber: '05',
    },
    {
        img: <ServiceItem6 className="serviceIcon" />,
        serviceTitle: 'BIM_Services',
        serviceNumber: '06',
    },
    {
        img: <ServiceItem7 className="serviceIcon" />,
        serviceTitle: 'Landscape_Design',
        serviceNumber: '07',
    },
    {
        img: <ServiceItem8 className="serviceIcon" />,
        serviceTitle: 'Graphic_Design',
        serviceNumber: '08',
    },
    {
        img: <ServiceItem9 className="serviceIcon" />,
        serviceTitle: 'Electro_Mechanical',
        serviceNumber: '09',
    },
    {
        img: <ServiceItem10 className="serviceIcon" />,
        serviceTitle: 'Value_Engineering',
        serviceNumber: '10',
    },

]
export const scrollImages = [
    {
        img: P1img1,
        // slideLocation: 'slideLocation1',
        slideTitle: 'slideTitle1',
        slideText: 'slideText1',
    },
    {
        img: P2img1,
        slideLocation: 'slideLocation2',
        slideTitle: 'slideTitle2',
        slideText: 'slideText2',
    },
    {
        img: P3img1,
        slideLocation: 'slideLocation3',
        slideTitle: 'slideTitle3',
        slideText: 'slideText3',
    },
    {
        img: P4img1,
        // slideLocation: 'slideLocation4',
        slideTitle: 'slideTitle4',
        slideText: 'slideText4',
    },
    {
        img: P5img1,
        // slideLocation: 'slideLocation5',
        slideTitle: 'slideTitle5',
        slideText: 'slideText5',
    },
    {
        img: P6img1,
        // slideLocation: 'slideLocation6',
        slideTitle: 'slideTitle6',
        slideText: 'slideText6',
    },
    {
        img: P7img1,
        // slideLocation: 'slideLocation7',
        slideTitle: 'slideTitle7',
        slideText: 'slideText7',
    },
    {
        img: P8img1,
        slideLocation: 'slideLocation8',
        slideTitle: 'slideTitle8',
        slideText: 'slideText8',
    },
    {
        img: P9img1,
        slideLocation: 'slideLocation9',
        slideTitle: 'slideTitle9',
        slideText: 'slideText9',
    },
    {
        img: P10img1,
        // slideLocation: 'slideLocation10',
        slideTitle: 'slideTitle10',
        slideText: 'slideText10',
    },
    {
        img: P11img1,
        slideLocation: 'slideLocation11',
        slideTitle: 'slideTitle11',
        slideText: 'slideText11',
    },
    {
        img: P12img1,
        slideLocation: 'slideLocation12',
        slideTitle: 'slideTitle12',
        slideText: 'slideText12',
    },
    {
        img: P13img1,
        slideLocation: 'slideLocation13',
        slideTitle: 'slideTitle13',
        slideText: 'slideText13',
    },
    {
        img: P14img1,
        slideLocation: 'slideLocation14',
        slideTitle: 'slideTitle14',
        slideText: 'slideText14',
    },
    {
        img: P15img1,
        slideLocation: 'slideLocation15',
        slideTitle: 'slideTitle15',
        slideText: 'slideText15',
    },
    {
        img: P16img1,
        slideLocation: 'slideLocation16',
        slideTitle: 'slideTitle16',
        slideText: 'slideText16',
    },
    {
        img: P17img1,
        slideLocation: 'slideLocation17',
        slideTitle: 'slideTitle17',
        slideText: 'slideText17',
    },
    {
        img: P18img1,
        slideLocation: 'slideLocation18',
        slideTitle: 'slideTitle18',
        slideText: 'slideText18',
    },
    {
        img: P19img1,
        slideLocation: 'slideLocation19',
        slideTitle: 'slideTitle19',
        slideText: 'slideText19',
    },
    
    /* {
      img: img2,
      slideLocation: 'slideLocation2',
      slideTitle: 'slideTitle2',
      slideText: 'slideText1',
    },
    {
      img: img3,
      slideLocation: 'slideLocation3',
      slideTitle: 'slideTitle3',
      slideText: 'slideText1',
    },
    {
      img: img4,
      slideLocation: 'slideLocation4',
      slideTitle: 'slideTitle4',
      slideText: 'slideText1',
    },
    {
        img: img3,
        slideLocation: 'slideLocation3',
        slideTitle: 'slideTitle3',
        slideText: 'slideText1',
    }, */
    
];
export const galleryImages = [
    [P1img1, P1img2, P1img3],
    [P2img1],
    [
        P3img1,
        P3img2,
        P3img3,
    ],
    [
        P4img1,
        P4img2,
        P4img3,
        P4img4,
        P4img5,
        P4img6,
    ],
    [
        P5img1,
        P5img2,
        P5img3,
        P5img4,
    ],
    [
        P6img1,
        P6img2,
        P6img3,
    ],
    [
        P7img1,
        P7img2,
    ],
    [
        P8img1,
        P8img2,
    ],
    [
        P9img1,
        P9img2,
        P9img3,
    ],
    [
        P10img1,
        P10img2,
        P10img3,
    ],
    [
        P11img1,
        P11img2,
        P11img3,
        P11img4,
        P11img5,
        P11img6,
    ],
    [
        P12img1,
        P12img2,
        P12img3,
        P12img4,
        P12img5,
    ],
    [
        P13img1,
        P13img2,
        P13img3,
        P13img4,
    ],
    [
        P14img1,
        P14img2,
    ],
    [
        P15img1,
        P15img2,
        P15img3,
        P15img4,
    ],
    [
        P16img1,
        P16img2,
        P16img3,
    ],
    [
        P17img1,
    ],
    [
        P18img1,
        P18img2,
    ],
    [
        P19img1,
        P19img2,
        P19img3,
    ],
    
    /* [img1, img3],
    [img2, img3],
    [img1, img2],
    [img2, img4], */
];
export const ProjectOtherData = [
    {
        img: <WhyIcon1 />,
        heading: "whyTitle1",
        text: "whyText1",
        sequenceIndex: '0',
    },
    {
        img: <WhyIcon2 />,
        heading: "whyTitle2",
        text: "whyText2",
        sequenceIndex: '1',
    },
    {
        img: <WhyIcon3 />,
        heading: "whyTitle3",
        text: "whyText3",
        sequenceIndex: '2',
    },
    {
        img: <WhyIcon4 />,
        heading: "whyTitle4",
        text: "whyText4",
        sequenceIndex: '3',
    },
]
export const ProjectDigitalData = [
    {
        img: <WhyIcon5 />,
        heading: "whyTitle5",
        text: "whyText5",
        sequenceIndex: '0',
    },
    {
        img: <WhyIcon6 />,
        heading: "whyTitle6",
        text: "whyText6",
        sequenceIndex: '1',
    },
    {
        img: <WhyIcon7 />,
        heading: "whyTitle7",
        text: "whyText7",
        sequenceIndex: '2',
    },
    {
        img: <WhyIcon8 />,
        heading: "whyTitle8",
        text: "whyText8",
        sequenceIndex: '3',
    },
]
export const ClientData = [
    {
        logo: <img src={Client1} alt="client" />,

    },
    {
       logo: <img src={Client2} alt="client" />,
    },
    {
       logo: <img src={Client3} alt="client" />,
    },
    {
       logo: <img src={Client4} alt="client" />,
    },
    {
       logo: <img src={Client5} alt="client" />,
    },
    {
       logo: <img src={Client6} alt="client" />,
    },
    {
       logo: <img src={Client7} alt="client" />,
    },
    {
       logo: <img src={Client8} alt="client" />,
    },
    {
       logo: <img src={Client9} alt="client" />,
    },
    {
       logo: <img src={Client10} alt="client" />,
    },
    {
       logo: <img src={Client11} alt="client" />,
    },
    {
       logo: <img src={Client12} alt="client" />,
    },
    {
       logo: <img src={Client13} alt="client" />,
    },
    {
       logo: <img src={Client14} alt="client" />,
    },
]
export const socialIcon = [
    {
        img: <Twitter />,
        link: '#!',
    },

    {
        img: <Instagram />,
        link: '#!',
    },
    {
        img: <Tiktok />,
        link: '#!',
    },
    {
        img: <Linkedin />,
        link: '#!',
    },
];